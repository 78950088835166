import React, { useState } from 'react'

const Rsvp = props => {
  const [toggle, setToggle] = useState(false)

  const { guest } = props

  const frame = guestType => {
    if (guestType) {
      return (
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdOBt_VV5lglVCaL32rBK1e2PnDaGZY3vLuvun75aJqsEckhw/viewform?embedded=true"
          width="640"
          height="733"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading...
        </iframe>
      )
    } else {
      return (
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdpt0Jm91DuyIlW6S-tRZJ9DQYi8CDkJNcUn0dMlEvgaPDkHg/viewform?embedded=true"
          width="640"
          height="733"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading...
        </iframe>
      )
    }
  }

  return (
    <div>
      <button
        style={{
          background: '#44D7A8',
          border: '0',
          padding: '10px',
          width: '150px',
          cursor: 'pointer',
        }}
        onClick={() => setToggle(!toggle)}
      >
        RSVP now
      </button>
      {toggle && <div style={{ marginTop: '1rem' }}>{frame(guest)}</div>}
    </div>
  )
}

export default Rsvp
