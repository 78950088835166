import React from 'react'
import Image from './image'

const Header = ({ vip }) => (
  <div>
    <Image vip={vip} />
  </div>
)

export default Header
